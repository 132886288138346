<template>
  <div class="canvas-cell__wrapper" @click="openCommentForm">
    <div class="canvas-cell__title">
      {{ sectionTitle }}
    </div>
    <div class="canvas-cell__desc">{{ sectionDescription }}</div>
    <div class="canvas-cell__forms">
      <div class="placeholder-transparent">
        <div style="display: block; white-space: nowrap;"></div>
      </div>
      <div
        class="cell-placeholder-wrap"
        v-if="comments.length === 0"
      >
        <span
          class="cell-placeholder"
          v-show="!hideCellPlaceholder"
        >
          {{ cellPlaceholder }}
        </span>
      </div>
      <div
        class="canvas-cell__forms-wrap"
        v-if="comments"
      >
        <draggable
          :animation="200"
          @update="draggableUpdate"
          handle=".canvas-cell-field-drag"
        >
          <div
            :class="'comment_' + comment.id"
            v-for="comment in comments"
            :key="comment.id"
          >
            <section-comment
              :comment="comment"
              @update-comment="updateComment"
            />
          </div>
        </draggable>
        <div>
          <div>
            <section-comment
              :region-number="cellPlaceholder"
              @create-comment="createComment"
              ref="newCommentForm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionComment from "./LeanCanvasSectionComment.vue";
import Enum from '../../enum/Enum.js';
import draggable from 'vuedraggable'

export default {
  components: {
    SectionComment,
    draggable
  },

  props: {
    canvas: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      required: true
    },
    sectionTitle: {
      type: String,
      required: true
    },
    sectionDescription: {
      type: String,
      required: true
    },
    cellPlaceholder: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    newComment: '',
    isActive: true,
    regions: Enum
  }),

  computed: {
    hideCellPlaceholder () {
      const existingAlternatives = this.regions.ExistingAlternatives.val
      const earlyAdopters        = this.regions.EarlyAdopters.val
      const highLevelConcept     = this.regions.HighLevelConcept.val
      const erasePlaceholderRegion = [existingAlternatives, earlyAdopters, highLevelConcept]
      return (
        erasePlaceholderRegion.includes(this.cellPlaceholder)
      )
    }
  },

  mounted () {
    this.togglePlaceholder()
  },

  methods: {
    createComment (payload) {
      this.$emit('create-comment', payload);
    },

    updateComment (payload) {
      this.$emit('update-comment', payload);
    },

    togglePlaceholder () {
      if(this.comments.length > 0) {
        this.isActive = false
      }
    },

    draggableUpdate (e) {
      const commentId = parseInt(e.item.className.replace('comment_', ''))
      const newOrder = e.newIndex + 1
      const payload = {
        comment: {
          id: commentId,
          row_order_position: newOrder
        }
      }
      this.$emit('draggable-update', payload)
    },

    openCommentForm () {
      this.$refs.newCommentForm.createCommentForm()
    }
  }
}
</script>

<style scoped lang="scss">
.canvas-page {

  .canvas-cell__wrapper {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    min-height: 300px;
    height: 100%;
  }

  .canvas-cell__title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    align-items: center;
  }

  .canvas-cell__desc {
    font-size: 12px;
    color: #6b7c93;
    margin-bottom: 10px;
    white-space: pre-wrap;
  }

  .canvas-cell__forms {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    flex: 1 1 auto;
  }

  .placeholder-transparent {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
    color: transparent;
    opacity: 0;
    font-size: 80px;
    font-weight: 600px;
    box-sizing: border-box;
  }

  .cell-placeholder-wrap {
    position: absolute;
    font-size: 80px;
    top: calc(50% - 20px);
    left: 0;
    height: 80px;
    display: block;
    text-align: center;
    color: rgba(50,50,93,.2);
    font-weight: 600;
    line-height: 1;
    transition: .2s;
    background-color: initial;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
  }

  .cell-placeholder {
    display: inline-block;
  }

  .canvas-cell__forms-wrap {
    margin-left: -20px;
    margin-right: -20px;
    z-index: 1;
  }

  [class^='comment_']:hover,
  [class*=' comment_']:hover {
    background-color: #F9FCF6;
  }

}
</style>
