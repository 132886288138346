<template>
  <div class="canvases content-wrap">
    <div class="canvas-list">
      <div class="canvas-item canvas-item_create">
        <router-link
          class="canvas-item-panel"
          :to="{ name: 'New' }"
          tag="div"
        >
          <div class="canvas-item-name">
            リーンキャンバスを作成する
          </div>
        </router-link>
      </div>

      <div
        v-for="canvas in canvases"
        :key="canvas.id"
        class="canvas-item canvas-item_create"
      >
        <canvas-item
          :canvas="canvas"
          @destroy-canvas="destroyCanvas(canvas.id)"
          @duplicate-canvas="duplicateCanvas(canvas.id)"
        />
      </div>
    </div>
  </div>
</template>>

<script>
import CanvasItem from "./IndexCanvasItem";

export default {
  components: {
    CanvasItem
  },

  props: {
    canvases: {
      type: Array,
      required: true
    }
  },

  methods: {
    async destroyCanvas (canvasId) {
      this.$emit('destroy-canvas', canvasId);
    },

    async duplicateCanvas (canvasId) {
      const payload = {
        canvas: {
          id: canvasId
        }
      }
      this.$emit('duplicate-canvas', payload);
    }
  }
}
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.canvases {
  padding-top: 40px;
  margin-bottom: 100px;
}

.content-wrap {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.canvas-list {
  margin-top: 440x;
  flex-wrap: wrap;
  justify-content:space-between;
}

.canvas-item {
  width: 31%;
  height: 236px;
  margin: 0 3.5% 40px 0;
  float: left;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgba(53,104,235,0),
              0 2px 25px 4px rgba(50,50,93,.05),
              0 1px 4px 0 rgba(50,50,93,.15);
  transition-property: all;
  transition: .25s;
  cursor: pointer;
  position: relative;
  z-index: 1;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-box-align: center;
  box-align: center;
}

.canvas-item-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.canvas-item:nth-child(3n) {
  margin: 0 0 40px 0;
}

.canvas-item:hover {
  border: 2px solid #3568eb;
}

.canvas-item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: #3568eb;
  text-decoration: none;
  vertical-align: middle;
}

.canvas-item a span {
  position: absolute;
  display: table;
  margin: auto;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.canvas-item:hover {
  border-color: #3568eb;
}

.canvas-item_create {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f9fc;
  font-size: 24px;
  color: #3568eb;
  text-align: center;
}
</style>
