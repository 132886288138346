const EnumRegion = {
  CustomerSegments: {
    val: 1,
    text: "customer_segments",
    title: "顧客セグメント",
    description: "ターゲットとなる顧客とユーザー"
  },
  Problems: {
    val: 2,
    text: "problems",
    title: "課題",
    description: "顧客の上位3つの課題"
  },
  UniqueValueProposition: {
    val: 3,
    text: "unique_value_proposition",
    title: "独自の価値提案",
    description: "事業を知らない人に興味を持ってもらえるメッセージ"
  },
  Solutions: {
    val: 4,
    text: "solutions",
    title: "ソリューション",
    description: "それぞれの課題について考え得る解決策"
  },
  Channels: {
    val: 5,
    text: "channels",
    title: "チャネル",
    description: "顧客に認知してもらう手段"
  },
  Revenue: {
    val: 6,
    text: "revenue",
    title: "収益の流れ",
    description: "収益源"
  },
  CostStructure: {
    val: 7,
    text: "cost_structure",
    title: "コスト構造",
    description: "固定費と変動費"
  },
  KeyMetrics: {
    val: 8,
    text: "key_metrics",
    title: "主要指標",
    description: "事業を推進するためのKPI"
  },
  UnfairAdvantage: {
    val: 9,
    text: "unfair_advantage",
    title: "圧倒的な優位性",
    description: "簡単に真似、もしくは代替できないもの"
  },
  ExistingAlternatives: {
    val: 10,
    text: "existing_alternatives",
    title: "既存の代替品",
    description: "現状の課題の解決策"
  },
  EarlyAdopters: {
    val: 11,
    text: "early_adopters",
    title: "アーリーアダプター",
    description: "理想的な顧客の性格"
  },
  HighLevelConcept: {
    val: 12,
    text: "high_level_concept",
    title: "ハイレベルコンセプト",
    description: "サービスの価値を最も分かりやすく伝えるフレーズ"
  },
}

export default EnumRegion;
