<template>
  <index-canvas
    :canvases="canvases"
    @destroy-canvas="destroyCanvas"
    @duplicate-canvas="duplicateCanvas"
  />
</template>

<script>
import IndexCanvas from "../presentational/IndexCanvas";

const defaultErrorModalConfig = {
  title: 'エラー',
  text: 'キャンバス情報の取得に失敗しました',
  type: 'error',
  confirmButtonText: '閉じる'
}

export default {
  components: {
    IndexCanvas
  },

  data: () => ({
    canvases: []
  }),

  mounted () {
    this.fetchCanvas();
  },

  methods: {
    async isUserLoggedIn () {
      try {
        const res = await this.$axios.get('api/sessions/show')
        return res.data.is_logged_in
      } catch(e) {
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'ログイン情報の取得に失敗しました'
        })
      }
    },

    async fetchCanvas () {
      const isLoggedIn = await this.isUserLoggedIn()
      if(!isLoggedIn) { return }
      try {
        const res = await this.$axios.get('api/canvases')
        this.canvases = res.data.canvases
      } catch(e) {
        console.error(e)
        this.$swal(defaultErrorModalConfig)
      }
    },

    async destroyCanvas (canvasId) {
      const res = await this.$swal({
        title: 'データ削除',
        text: '本当にキャンバスを削除してもよろしいですか？',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'はい',
        cancelButtonText: 'キャンセル'
      })

      if (res.value) {
        try {
          await this.$axios.delete(`api/canvases/${canvasId}`)
          this.canvases = this.canvases.filter(canvas => canvas.id !== canvasId)
          await this.$swal({
            text: 'キャンバスを削除しました',
            confirmButtonText: '閉じる'
          })
        } catch (e) {
          console.error(e)
          this.$swal({
            ...defaultErrorModalConfig,
            text: 'キャンバスの削除に失敗しました'
          })
        }
      }
    },

    async duplicateCanvas (payload) {
      try {
        const res = await this.$axios.post('api/canvases/duplicate', payload)
        this.canvases.unshift(res.data.canvas)
        await this.$swal({
          text: 'キャンバスを複製しました',
          confirmButtonText: '閉じる'
        })
      } catch(e) {
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'キャンバスの複製に失敗しました'
        })
      }
    }
  }
}

</script>
