<template>
  <div class="canvas-cell-field-wrap" v-if="comment">
    <textarea
      v-model="commentBody"
      @blur="isEditing=false; updateComment($event)"
      @keydown.enter="$event.target.blur()"
      @compositionstart="composing = true"
      @compositionend="composing = false"
      :ref="'focusInput_' + this.comment.id"
      v-show="isEditing"
      class="canvas-cell-field"
      @keyup="adjustTextareaHeight"
      :style="{ height: `${textareaHeight}px` }"
    />

    <div
      v-show="!isEditing"
      @click="editCommentForm($event)"
      class="canvas-cell-field"
    >
      {{ commentBody }}
      <span class="canvas-cell-field-drag">:::</span>
    </div>
  </div>

  <div class="canvas-cell-field-wrap" v-else>
    <textarea
      v-model="commentBody"
      @blur="formBlur(regionNumber, $event)"
      @keydown.enter="inputComment(regionNumber, $event)"
      @compositionstart="composing = true"
      @compositionend="composing = false"
      @keyup="adjustTextareaHeight"
      ref="textarea"
      v-show="isFormOpening"
      class="canvas-cell-field"
      :style="{ height: `${textareaHeight}px` }"
    />

    <div
      v-show="!isFormOpening"
      @click="createCommentForm()"
      class="canvas-cell-field"
    >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: false
    },
    regionNumber: {
      type: Number,
      required: false
    }
  },

  data: () => ({
    isFormOpening: false,
    isEditing: false,
    commentBody: '',
    composing: false,
    textareaHeight: 0
  }),

  computed: {
    commentForm () {
      if (this.comment) {
        const focusInput = `focusInput_${this.comment.id}`
        return this.$refs[focusInput]
      } else {
        return this.$refs.textarea
      }
    },
  },

  mounted () {
    if (this.comment) {
      this.commentBody = this.comment.body
    }
  },

  methods: {
    adjustTextareaHeight () {
      this.textareaHeight = this.commentForm.scrollHeight
    },

    editCommentForm (e) {
      e.stopPropagation();
      this.isEditing = true
      this.$nextTick(() => {
        this.commentForm.focus()
        this.commentForm.style.height = 'auto'
        this.commentForm.style.height = this.commentForm.scrollHeight + 'px'
      })
    },

    updateComment (e) {
      if(this.composing) return;
      e.preventDefault()
      this.isEditing = false
      const payload = {
        comment: {
          id: this.comment.id,
          body: this.commentBody
        }
      }
      this.$emit('update-comment', payload)
    },

    createCommentForm () {
      this.isFormOpening = true
      this.$refs.textarea.style.height = '33px'
      this.$nextTick(() => this.$refs.textarea.focus())
    },

    inputComment (region, e) {
      if(!this.commentBody || this.composing) { return }
      e.preventDefault()

      const payload = {
        comment: {
          body: this.commentBody,
          region: region
        }
      }
      this.$emit('create-comment', payload);
      this.commentBody = '';
      this.canCommentSubmit = false;
    },

    formBlur (region, e) {
      this.isFormOpening = false
      this.inputComment(region, e);
    }
  }
}
</script>

<style lang="scss">
.canvas-page {

  .canvas-cell-field-wrap {
    min-height: 33px;
  }

  .canvas-cell-field-drag {
    position: absolute;
    left: 4px;
    top: calc(30%);
    width: 14px;
    height: 10px;
    text-align: center;
    font-size: 10px;
    color: rgba(50,50,93,.4);
    cursor: move;
    opacity: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
  }

  .canvas-cell-field {
    margin: 0;
    padding: 6px 20px;
    min-height: 30px;
    box-sizing: border-box;
    color: #000;
    font-size: 14px;
    transition: .2s;
    -webkit-appearance: none;
    word-break: break-word;
    border: none;
    width: 100%;
    position: relative;
    resize: none;
    overflow-y: hidden;
    transition: none;
    display: block;
    text-shadow: none;
    text-align: start;
    overflow-wrap: break-word;
  }

  textarea.canvas-cell-field:focus {
    outline: none;
    background-color: #f5f9fc;
  }

  .canvas-cell-field:hover {
    .canvas-cell-field-drag {
      opacity: 1;
    }
  }

}
</style>
