import Vue from 'vue/dist/vue.esm.js';
import axios from "axios";
import VueAxiosPlugin from "./plugins/vue-axios";
import router from './router/router'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import App from "./components/App.vue";

import SocialSharing from 'vue-social-sharing';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.prototype.$axios = axios
Vue.use(VueAxiosPlugin, { axios })
Vue.use(VueSweetalert2)
Vue.use(SocialSharing)

library.add(faCoffee)
library.add(faFacebook)
library.add(faTwitter)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
