<template>
  <lean-canvas
    v-if="isNewCanvas || isEditCanvas"
    @create-canvas="createCanvas"
    @create-comment="createComment"
    @update-canvas="updateCanvas"
    @update-comment="updateComment"
    @delete-comment="deleteComment"
    @draggable-update="draggableUpdate"
    @toggle-private="updateCanvas"
    :canvas="canvas"
    :comments="comments"
  />
</template>

<script>
import LeanCanvas from "../presentational/LeanCanvas";

const defaultErrorModalConfig = {
  title: 'エラー',
  text: 'キャンバス情報の取得に失敗しました',
  type: 'error',
  confirmButtonText: '閉じる'
}

export default {
  components: {
    LeanCanvas
  },

  data: () => ({
    canvas: {},
    comments: [],
  }),

  computed: {
    isNewCanvas () {
      return !this.$route.params.id
    },

    isEditCanvas () {
      return !this.isNewCanvas && this.canvas.id
    },
  },

  created () {
    if(this.$route.params.id) {
      this.fetchCanvas()
    }
  },

  methods: {
    async fetchCanvas () {
      try {
        const res = await this.$axios.get(`/api/canvases/${this.$route.params.id}`)
        this.canvas = res.data.canvas
        this.fetchComments(this.canvas.id)
      } catch (e) {
        console.error(e)
        await this.$swal(defaultErrorModalConfig)
        return location.href = '/'
      }
    },

    async fetchComments (id) {
      try {
        const res = await this.$axios.get(`/api/canvases/${id}/comments`)
        res.data.comments.forEach(comment => this.comments.push(comment))
      } catch (e) {
        console.error(e)
        await this.$swal({
          ...defaultErrorModalConfig,
          text:'コメント情報の取得に失敗しました'  
        })
      }
    },

    async createCanvas (payload) {
      if (this.isEditCanvas) { return }
      try {
        const res = await this.$axios.post('/api/canvases', payload)
        this.canvas = res.data.canvas
      } catch (e) {
        if (e.response.status === 401) {
          console.log(e)
          await this.$swal({
            ...defaultErrorModalConfig,
            title: 'ログインがお済みではありません',
            text: '会員登録もしくはログインしてください',
            type: 'info',
            confirmButtonText: 'OK'
          })
          return location.href = '/users/sign_up'
        }
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'キャンバスの作成に失敗しました'
        })
      }
    },

    async createComment (payload) {
      try {
        const res = await this.$axios.post(`/api/canvases/${this.canvas.id}/comments`, payload)
        this.comments.push(res.data.comments)
      } catch (e) {
        console.error(e)
        this.$swal({
          ...this.defaultErrorModalConfig,
          text: 'キャンバス情報の書き込みに失敗しました'
        })
      }
    },

    async updateCanvas (payload) {
      try {
        await this.$axios.put(`/api/canvases/${this.canvas.id}`, payload)
      } catch (e) {
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'データ更新に失敗しました'
        })
      }
    },

    async updateComment (payload) {
      try {
        const putCommentPath = `canvases/${this.canvas.id}/comments/${payload.comment.id}`
        await this.$axios.put(`/api/${putCommentPath}`, payload)
      } catch (e) {
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'データ更新に失敗しました'
        })
      }
    },

    async deleteComment (commentID) {
      const deleteCommentPath = `canvases/${this.canvas.id}/comments/${commentID}`
      try {
        const res = await this.$axios.delete(`/api/${deleteCommentPath}`)
        const targetID = res.data.comments.id
        this.comments.some((v, i) => {
          if (v.id === targetID) { this.comments.splice(i, 1) }
        })
      } catch (e) {
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'データ削除に失敗しました'
        })
      }
    },

    async draggableUpdate (payload) {
      try {
        const sortPath = `canvases/${this.canvas.id}/comments/${payload.comment.id}/sort`
        await this.$axios.put(`/api/${sortPath}`, payload)
      } catch (e) {
        console.error(e)
        this.$swal({
          ...defaultErrorModalConfig,
          text: 'データの並び替えに失敗しました'
        })
      }
    }
  }
}
</script>
