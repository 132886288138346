<template>
  <router-link
    :to="{ name: 'Edit', params: { id: canvas.id } }"
    :key="canvas.id"
    tag="div"
    class="canvas-item-panel"
  >
    <div class="canvas-item-name">
      {{ canvas.title }}
    </div>
    <div
      class="canvas-item-actions"
      @click="showMenu($event)"
    >
      <div
        @click="isActive = !isActive"
        class="canvas-item-link-actions icon-menu"
      >
      </div>
      <div
        class="drop-content"
        :class="{'show': isActive}"
      >
        <ul class="drop-menu" id="drop-menu">
          <li @click="$emit('destroy-canvas')">
            <div class="menu-button">削除</div>
          </li>
          <li @click="duplicateCanvas()">
            <div class="menu-button">複製</div>
          </li>
        </ul>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    canvas: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    isActive: false
  }),

  methods: {
    showMenu (e) {
      e.preventDefault()
    },

    duplicateCanvas () {
      this.isActive = !this.isActive
      this.$emit('duplicate-canvas');
    }
  }
}
</script>

<style scoped>
.canvas-item-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.canvas-item-actions {
  position: absolute;
  right: 0px;
  top: 0px;
}

.canvas-item-actions .drop-content {
  bottom: 0;
  left: auto;
  top: auto;
  right: calc(100% + 4px)
}

.drop-content {
  display: none;
  z-index: 100;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background: #fff;
  box-shadow: 0 2px 15px rgba(50,50,93,.16);
  border-radius: 3px;
  -webkit-transform-origin: top;
  transform-origin: top;
}

.drop-content.show {
  display: block;
}

.drop-menu {
  list-style: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.drop-menu li {
  position: relative;
  list-style: none;
}

.drop-menu li span {
  display: block;
  padding: 12px 16px;
  white-space: nowrap;
  text-decoration: none;
  color: black;
}

.drop-menu li span:hover {
  color: blue;
}

.canvas-item-link-actions {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
}

.icon-menu::before {
  content: "+"
}

.menu-button {
  padding: .2rem .4rem;
  min-width: 70px;
  font-size: 1.25rem;
}

</style>
