import Vue from 'vue/dist/vue.esm.js'
import VueRouter from 'vue-router'
import LeanCanvasContainer from '../components/container/LeanCanvasContainer'
import IndexCanvasContainer from '../components/container/IndexCanvasContainer'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: IndexCanvasContainer
    },
    {
      path: '/canvases/new',
      name: 'New',
      component: LeanCanvasContainer
    },
    {
      path: '/canvases/:id',
      name: 'Edit',
      component: LeanCanvasContainer
    }
  ]
})
