<template>
  <div
    id="canvas"
    class="canvas-page canvas-page__color1"
    @click="createCanvas()"
  >
    <div class="canvas-page__header no_print">
      <div class="canvas-page__link-back">
        <router-link :to="{ name: 'Index' }">←</router-link>
      </div>
      <div class="canvas-page__info">
        <div class="canvas-page__name">
          <input
            type="text"
            placeholder="Your Canvas Name"
            v-model="canvasTitle"
            @keydown.enter="updateCanvas($event)"
            @focus="isActive=true"
            @blur="updateCanvas($event); isActive = false"
            @compositionstart="composing = true"
            @compositionend="composing = false"
          >
          <span v-if="isActive" class="canvas-page__name-hint">Enterで保存</span>
        </div>
      </div>

      <div class="canvas-page__private">
        <input
          type="checkbox"
          id="checkbox"
          true-value=1
          false-value=0
          v-model="canvasStatus"
          @change="togglePrivate()"
        >
        <label for="checkbox">公開する</label>
      </div>

      <button
        @click="printCanvas()"
        target="_blank"
        class="btn btn-outline-dark"
      >Print</button>

      <social-sharing
        :url="currentURL"
        :title="this.canvasTitle"
        description="リーンキャンバスを使って新規事業を考えてみた"
        hashtags="リーンキャンバス, mof-canvas"
        network-tag="div"
        inline-template
      >
        <div class="canvas-page__share-wrap">
          <network network="twitter" class="canvas-page__share">
            <font-awesome-icon
              :icon="{ prefix: 'fab', iconName: 'twitter' }"
              class="share-icon i-twitter"
            />
          </network>

          <network network="facebook" class="canvas-page__share">
            <font-awesome-icon
              :icon="{ prefix: 'fab', iconName: 'facebook' }"
              class="share-icon i-facebook"
            />
          </network>
        </div>
      </social-sharing>
    </div>
    <div
      id="print-section"
      class="canvas-page__view"
    >
      <div class="canvas-cell-p2">
        <canvas-section
          :comments="mapRegionComments(regions.Problems.text)"
          :canvas = "this.canvas"
          :section-title = "regions.Problems.title"
          :section-description = "regions.Problems.description"
          :cell-placeholder = "regions.Problems.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
        <canvas-section
          :comments="mapRegionComments(regions.ExistingAlternatives.text)"
          :canvas = "this.canvas"
          :section-title = "regions.ExistingAlternatives.title"
          :section-description = "regions.ExistingAlternatives.description"
          :cell-placeholder = "regions.ExistingAlternatives.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p4">
        <canvas-section
          :comments="mapRegionComments(regions.Solutions.text)"
          :canvas = "this.canvas"
          :section-title = "regions.Solutions.title"
          :section-description = "regions.Solutions.description"
          :cell-placeholder = "regions.Solutions.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p8">
        <canvas-section
          :comments="mapRegionComments(regions.KeyMetrics.text)"
          :canvas = "this.canvas"
          :section-title = "regions.KeyMetrics.title"
          :section-description = "regions.KeyMetrics.description"
          :cell-placeholder = "regions.KeyMetrics.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p3">
        <canvas-section
          :comments="mapRegionComments(regions.UniqueValueProposition.text)"
          :canvas = "this.canvas"
          :section-title = "regions.UniqueValueProposition.title"
          :section-description = "regions.UniqueValueProposition.description"
          :cell-placeholder = "regions.UniqueValueProposition.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
        <canvas-section
          :comments="mapRegionComments(regions.HighLevelConcept.text)"
          :canvas = "this.canvas"
          :section-title = "regions.HighLevelConcept.title"
          :section-description = "regions.HighLevelConcept.description"
          :cell-placeholder = "regions.HighLevelConcept.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p9">
        <canvas-section
          :comments="mapRegionComments(regions.UnfairAdvantage.text)"
          :canvas = "this.canvas"
          :section-title = "regions.UnfairAdvantage.title"
          :section-description = "regions.UnfairAdvantage.description"
          :cell-placeholder = "regions.UnfairAdvantage.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p5">
        <canvas-section
          :comments="mapRegionComments(regions.Channels.text)"
          :canvas = "this.canvas"
          :section-title = "regions.Channels.title"
          :section-description = "regions.Channels.description"
          :cell-placeholder = "regions.Channels.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p1">
        <canvas-section
          :comments="mapRegionComments(regions.CustomerSegments.text)"
          :canvas = "this.canvas"
          :section-title = "regions.CustomerSegments.title"
          :section-description = "regions.CustomerSegments.description"
          :cell-placeholder = "regions.CustomerSegments.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
        <canvas-section
          :comments="mapRegionComments(regions.EarlyAdopters.text)"
          :canvas = "this.canvas"
          :section-title = "regions.EarlyAdopters.title"
          :section-description = "regions.EarlyAdopters.description"
          :cell-placeholder = "regions.EarlyAdopters.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p7">
        <canvas-section
          :comments="mapRegionComments(regions.CostStructure.text)"
          :canvas = "this.canvas"
          :section-title = "regions.CostStructure.title"
          :section-description = "regions.CostStructure.description"
          :cell-placeholder = "regions.CostStructure.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
      <div class="canvas-cell-p6">
        <canvas-section
          :comments="mapRegionComments(regions.Revenue.text)"
          :canvas = "this.canvas"
          :section-title = "regions.Revenue.title"
          :section-description = "regions.Revenue.description"
          :cell-placeholder = "regions.Revenue.val"
          @create-comment="createComment"
          @update-comment="updateComment"
          @draggable-update="draggableUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CanvasSection from './LeanCanvasSection';
import SocialSharing from 'vue-social-sharing';
import Enum from '../../enum/Enum';

export default {
  components: {
    CanvasSection,
    SocialSharing
  },

  props: {
    canvas: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    canvasTitle: '',
    canvasStatus: 0,
    composing: false,
    isActive: false,
    regions: Enum
  }),

  computed: {
    currentURL () {
      return location.origin + this.$route.fullPath
    },
  },

  mounted () {
    this.fetchCanvasInfo()
  },

  methods: {
    async fetchCanvasInfo () {
      this.canvasTitle = this.canvas.title
      this.canvasStatus = this.canvas.status
    },

    async createCanvas () {
      if (!this.canvas.id) {
        const canvasPublic = 0;
        const payload = {
          canvas: {
            title: "untitled",
            is_private: canvasPublic
          }
        }
        this.$emit('create-canvas', payload)
      }
    },

    async createComment (payload) {
      this.$emit('create-comment', payload);
    },

    async updateCanvas (e) {
      if (!this.canvas.id || this.composing) { return }
      e.preventDefault()

      const payload = { canvas: { title: this.canvasTitle } }
      this.$emit('update-canvas', payload);
    },

    async togglePrivate () {
      const payload = { canvas: { status: this.canvasStatus } }
      this.$emit('toggle-private', payload);
    },

    async updateComment (payload) {
      if (payload.comment.body === '') {
        return this.deleteComment(payload.comment.id)
      }
      this.$emit('update-comment', payload);
    },

    async deleteComment (commentID) {
      this.$emit('delete-comment', commentID);
    },

    async draggableUpdate (payload) {
      this.$emit('draggable-update', payload);
    },

    mapRegionComments (region) {
      const regionComment = []
      this.comments.forEach(function(comment) {
        if (comment.region === region) {
          regionComment.push(comment);
        }
      });
      return regionComment;
    },

    printCanvas () {
      window.print()
    }
  }
}
</script>

<style lang="scss">
.canvas-page {

  padding: 40px 15px 20px;

  &__color1 {
    background-color: rgba(53, 216, 226, 0.61);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__link-back {
    flex-basis: 40px;
    flex-grow: 0;
    margin-top: 4px;
  }

  &__link-back a {
    display: inline-block;
    width: 24px;
    height: 24px;
    text-decoration: none;
    color: black;
  }

  &__info {
    flex-basis: 100%;
  }

  &__name {
    margin: 0 0 4px;
    height: 100%;
    position: relative;
  }

  &__name-hint {
    color: #6b7c93;
    font-size: 14px;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    top: 80%;
  }

  &__name input {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #32325d;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    height: auto;
  }
  
  &__private {
    flex-basis: 15%;
  }

  &__share-wrap {
    flex-basis: 20%;
    display: flex;
    padding-left: 10px;
  }

  &__share {
    cursor: pointer;
    margin-left: 10px;
  }
  .share-icon {
    width: 2em;
    height: 2em;
  }
  .i-twitter {
    color: #38A1F3;
  }
  .i-facebook {
    color: #3A5999;
  }

  &__view {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, calc(20% - 2px));
    grid-gap: 2px;
    grid-auto-flow: column;
    border: 1px solid #7D7D7D;
    background-color: #7D7D7D;
  }

  [class^='canvas-cell-p'],
  [class*=' canvas-cell-p'] {
    background-color: #fff;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: .2s;
  }

  [class^='canvas-cell-p']:hover,
  [class*=' canvas-cell-p']:hover {
    background-color: #F9FCF6;
    .cell-placeholder-wrap {
      color: rgba(50,50,93,.4);
      span {
        transform: scale(1.05);
      }
    }
  }

  .canvas-cell-p2 {
    grid-column: 1;
    grid-row: 1 / 3;
    .canvas-cell__wrapper {
      flex-basis: 70%;
    }
  }
  .canvas-cell-p4 {
    grid-column: 2;
    grid-row: 1;
  }
  .canvas-cell-p8 {
    grid-column: 2;
    grid-row: 2;
  }
  .canvas-cell-p3 {
    grid-column: 3;
    grid-row: 1 / 3;
    .canvas-cell__wrapper {
      flex-basis: 70%;
    }
  }
  .canvas-cell-p9 {
    grid-column: 4;
    grid-row: 1;
  }
  .canvas-cell-p5 {
    grid-column: 4;
    grid-row: 2;
  }
  .canvas-cell-p1 {
    grid-column: 5;
    grid-row: 1 / 3;
    .canvas-cell__wrapper {
      flex-basis: 70%;
    }
  }
  .canvas-cell-p7 {
    grid-column: 1;
    grid-row: 3;
    width: calc(250% + 3px);
  }
  .canvas-cell-p6 {
    grid-column: 1;
    grid-row: 3;
    width: calc(250% + 3px);
    margin-left: calc(250% + 5px);
  }

}

@media print {
  .no_print {
    display: none;
  }
  #print-section {
    width: 420mm;
    height: 297mm;
  }
  .canvas-page__view {
    grid-template-columns: repeat(5, calc(19.7% - 2px));
    grid-gap: 0.5px;
    max-height: 100px;
  }
  .canvas-cell__wrapper {
    min-height: 290px;
  }
  [class^='canvas-cell-p'],
  [class*=' canvas-cell-p'] {
    border: solid 0.5px black;
  }
  .canvas-cell-p7 {
    width: calc(250% + 1px);
  }
  .canvas-cell-p6 {
    width: calc(250% + 1px);
    margin-left: calc(250% + 1.5px);
  }
  @page {
    size: A4 landscape;
  }
}
</style>
